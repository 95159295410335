import React, { useCallback, useEffect, useState } from 'react';
import { Content, useNotification, ButtonWithIcon,  SmallInput, PageHeader} from 'scorer-ui-kit';
import styled, { css } from 'styled-components';
import { getLocationData, saveLocationData } from 'services/apiConfig';
import { useTranslation } from 'react-i18next';

const Header = styled.div`
  width: 100%;
  max-width: 940px !important;
  //height: 108px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  line-height: 1.2;
  > div:first-child > div > a {
    max-width: 750px;
    overflow-wrap: break-word;
    white-space: break-spaces;
    bottom: 34.5px;
    top: unset;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const PageHeaderComponent = styled.div`
  display: flex;
  flex-direction: column;
`;

const MainContent = styled.div`
  padding: 20px;
  margin: 0;
  max-width: 1200px !important;
  border-radius: 3px;
  background-color: #fafcfc;
  box-shadow: 0 4px 9px 0 rgba(152, 174, 189, 0.05);
  border: solid 1px #eee;
`;

const ButtonWithIconFormat = styled(ButtonWithIcon)`
  &:focus {
    outline: 2px solid #838383;
  }
`;


function useDebounce(value: any) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => setDebouncedValue(value), 250);
    return () => clearTimeout(handler);
  }, [value]);
  return debouncedValue;
}


const CameraConfiguration: React.FC = React.memo(() => {
  const { sendNotification } = useNotification();
  const { t } = useTranslation(['CommonDict']);
  const [isLoading, setIsLoading] = useState(true);
  const [locationId, setLocationId] = useState(0);
  const [locationName, setLocationName] = useState('New Location');
  const [locationStart, setLocationStart] = useState('00:00:00');
  const [locationEnd, setLocationEnd] = useState('23:59:59');
  const [locationThreshold_1, setLocationThreshold_1] = useState(50);
  const [locationThreshold_2, setLocationThreshold_2] = useState(50);

  // get camera list
  const getData = useCallback(async() => {
    try{
      const res: any = await getLocationData();
      if (res.data.StatusCode === 200) {
        const data = res.data.data;
        setLocationId(data.locationId);
        setLocationName(data.locationName);
        setLocationStart(data.locationStart);
        setLocationEnd(data.locationEnd);
        setLocationThreshold_1(data.locationThreshold_1);
        setLocationThreshold_2(data.locationThreshold_2);
      }
      else{
        console.error(res.data.Message);
      }
    }catch(error){
      console.error(error);
    }
    setIsLoading(false);
  }, []);
  useEffect(() => {
    getData();
  }, [getData]);

  // *************************************
  // ++++++++++++++ main ++++++++++++++++ 
  // *************************************
  const save_Location = useCallback(async () => {
    if(locationName === '' || locationStart === ''  || locationEnd === '') {
      sendNotification({ type: 'error', message: t('Cant be null, failed to save.') });
      return;
    }
    if(locationThreshold_2 <= locationThreshold_1) {
      sendNotification({ type: 'error', message: t('locationThreshold_2 less than locationThreshold_1') });
      return;
    }

    try {
      const res = await saveLocationData({
        locationId, locationName,
        locationStart, locationEnd,
        locationThreshold_1, locationThreshold_2
      });
      if (res.data.StatusCode === 200) {
        setLocationId(res.data.data.id);
        sendNotification({ type: 'success', message: t('Saved area successfully.') });
      }else{
        sendNotification({ type: 'error', message: t('Failed to save.') });
      }
    } catch (error) {
      console.error(error);
      sendNotification({ type: 'error', message: t('Failed to save.') });
    }
  }, [locationId, locationName, locationStart, locationEnd, locationThreshold_1, locationThreshold_2, sendNotification, t]);
  // ------------------------------------------


  const debounced_locationThreshold_2 = useDebounce(locationThreshold_2);
  useEffect(() => {
    setLocationThreshold_2(debounced_locationThreshold_2 <= locationThreshold_1 ? (locationThreshold_1 + 1)
      : debounced_locationThreshold_2 <= 1 ? 1
        : debounced_locationThreshold_2 >= 100 ? 100
          : debounced_locationThreshold_2);
  }, [debounced_locationThreshold_2, locationThreshold_1]);

  return (
    <Content>
      <Header>
        <PageHeaderComponent>
          <PageHeader
            icon='ViewSettings'
            updateDocTitle={false}
            title={'拠点設定'}
          />
        </PageHeaderComponent>
      </Header>
      <MainContent>
        <SmallInput
          label={t('location name')}
          type='text'
          value={isLoading ? '' : locationName}
          onChange={(e) => setLocationName(e.target.value)}
        />
        <SmallInput
          label={t('business start')}
          type='text'
          value={isLoading ? '' : locationStart}
          onChange={(e) => setLocationStart(e.target.value)}
        />
        <SmallInput
          label={t('business end')}
          type='text'
          value={isLoading ? '' : locationEnd}
          onChange={(e) => setLocationEnd(e.target.value)}
        />
        <SmallInput
          label={t('Congestion threshold 1')}
          type='text'
          value={isLoading ? '' : locationThreshold_1}
          onChange={(e) => {
            const newValue = Number(e.target.value.replace(/[^0-9]/g, ''));
            setLocationThreshold_1(newValue >= locationThreshold_2 ? (locationThreshold_2 - 1) : newValue <= 0 ? 0 : newValue >= 99 ? 99 : newValue);
          }}
        />
        <SmallInput
          label={t('Congestion threshold 2')}
          type='text'
          value={isLoading ? '' : locationThreshold_2}
          onChange={(e) => {
            const newValue = Number(e.target.value.replace(/[^0-9]/g, ''));
            setLocationThreshold_2(newValue);
          }}
        />
        <ButtonWithIconFormat tabIndex={0} design='primary' icon='Add' position='left' onClick={save_Location}>{t('Save')}</ButtonWithIconFormat>
      </MainContent>
    </Content>
  );
});

export default CameraConfiguration;