import React,  {useState, useEffect, useCallback, FC, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Route, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SecureRoute from 'SecureRoute';
import TokenService from 'services/tokenService';
import clonedeep from 'lodash.clonedeep';

import Role from 'components/Role';
import { getGuestUser, getConUrlList, getLocationData } from './services/apiConfig';
import { SUPERSET_URL, SUPERSET_URL_DASHBOARD_1, SUPERSET_URL_DASHBOARD_2, SUPERSET_URL_DASHBOARD_3 } from './constants';

import styled from 'styled-components';
import { MainMenu, Layout, TopBar, Spinner, Label } from 'scorer-ui-kit';
import { IMenuItemSubmenu, IMenuItemTop, IMenuTop } from 'scorer-ui-kit/dist/Global';
import LogoTextAppNameEn from './svg/logo-app-name_en.svg';
import logoMarkSvg from './svg/logo-mark.svg';
import PasswordModal from 'components/PasswordModal';

import Login from 'pages/Login';
import Summary from 'pages/Summary';
import Location from 'pages/Location';
import Configuration from 'pages/Configuration';

const MainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  user-select: none;
`;

const MainMenuContainer = styled(MainMenu)`
  user-select: none;
`;

const SpinnerBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  height: 89vh;
  align-items: center;
  justify-content: center;
`;

const StyledTopBar = styled.div`
  position: relative;
  & > div {
    z-index: unset;
  }
`;

const TitleTopBar = styled.div`
  color: rgba(120,138,144,0.72);
  font-size: 16px;
  position: relative;
  top: -46px;
  left: 40px;
`;


const App: FC = () => {
  const history = useHistory();
  const location = useLocation();
  const userName = TokenService.getUser();
  const { t } = useTranslation(['CommonDict']);
  const [menus, setMenus] = useState<IMenuTop>({ items: [] });
  const [userType, setUserType] = useState(false);
  const [guestUser, setGuestUser] = useState<boolean>(true);
  const [loadingGuest, setLoadingGuest] = useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = useState(!(TokenService?.getUser()) ? false : true);

  const [configurationUrlList, setConfigurationUrlList] = useState([]);
  const [carNumberUrlList, setCarNumberUrlList] = useState([]);
  const [loadingMenus, setLoadingMenus] = useState(true);
  const [title, setTitle] = useState('');
  const [locationName, setLocationName] = useState('New Location');
  const menuData = useMemo(() => [
    {
      'icon': 'DevicesScorerCamera',
      'title': 'サマリー',
      'href': '/summary'
    },
    {
      'icon': 'ViewSettings',
      'title': '拠点設定',
      'href': '/location'
    },
    { 
      'icon': 'Camera',
      'title': '駐車車両検知設定',
      'href': '/configuration',
      submenu: configurationUrlList.map((url:any, index:any) => ({
        title: `検知設定${index + 1}`,
        href: `/configuration${index + 1}`,
      }))
    },
    { 
      'icon': 'Analyse',
      'title': '車番検知設定',
      'href': '/car_number',
      submenu: carNumberUrlList.map((url:any, index:any) => ({
        title: `検知設定${index + 1}`,
        href: url,
        isExternalLink: true
      }))
    },
    { 
      'icon': 'Metabase',
      'title': 'Visualization',
      isExternalLink: true,
      'href': SUPERSET_URL ,
      submenu:[
        {
          'title': 'Hourly (Today)',
          'href': SUPERSET_URL_DASHBOARD_1,
          isExternalLink: true
        },
        {
          'title': 'Monthly (Last 3 months)',
          'href': SUPERSET_URL_DASHBOARD_2,
          isExternalLink: true
        }/*,
        {
          'title': 'Monthly (Last 3 months)',
          'href': SUPERSET_URL_DASHBOARD_3,
          isExternalLink: true
        }*/
      ]
    }
  ],[configurationUrlList, carNumberUrlList]);

  useEffect(() => {
    if (!isAuthenticated 
      && location.pathname !== '/login'
      && location.pathname !== '/summary'
      && location.pathname !== '/configuration'
    ) {
      history.replace('/login');
    }
    if (localStorage.getItem(`${window.location.hostname}_isMenuOpen`) === null) {
      localStorage.setItem(`${window.location.hostname}_isMenuOpen`, 'true');
    }
  }, [isAuthenticated, location, history]);


  const fetchGuestData = useCallback(async () => {
    setLoadingGuest(true);
    try {
      const { data: { data, status_code } } = await getGuestUser();
      if (status_code === 200) {
        setGuestUser(data.guest_enabled === 0 ? false : data.guest_enabled);
      } else {
        setGuestUser(false);
      }
    } catch (error) {
      setGuestUser(false);
      console.error((error as Error).message);
    } finally {
      setLoadingGuest(false);
    }
  }, []);
  
  useEffect(() => {
    fetchGuestData();
  }, [fetchGuestData]);


  // get location name
  const getLocationName = useCallback(async() => {
    try {
      const res: any = await getLocationData();
      if (res.data.StatusCode === 200) {
        const data = res.data.data;
        setLocationName(data.locationName);
      }
      else {
        console.error(res.data.Message);
      }
    } catch(error){
      console.error(error);
    }
  },[]);
  useEffect(() => {getLocationName();}, [getLocationName]);

  // get configuration url list
  const getUrlList = useCallback(async () => {
    try {
      const res: any = await getConUrlList();
      if (res.status === 200) {
        setConfigurationUrlList(res.data.configuration_urls);
        setCarNumberUrlList(res.data.car_number_urls);
        setLoadingMenus(false);
      }
    }
    catch (error) {
      console.error(error);
    }
  }, []);
  useEffect(() => {
    getUrlList();
  }, [getUrlList]);

  const translateMenus = useCallback(() => {
    const data = menuData.map((item: IMenuItemTop) => {
      if (item.submenu && item.submenu.length > 0) {
        const submenu = item.submenu.map((submenu: IMenuItemSubmenu) => {
          return { ...submenu, 'title': t(submenu.title) };
        });
        return { ...item, 'title': t(item.title), 'submenu': submenu };
      } else {
        return { ...item, 'title': t(item.title) };
      }
    });

    if (!TokenService.getUserType()) {        
      const guestMenuData = clonedeep(data);
      guestMenuData.splice(guestMenuData.findIndex(a => a.href === '/location'), 1);
      guestMenuData.splice(guestMenuData.findIndex(a => a.href === '/configuration'), 1);
      guestMenuData.splice(guestMenuData.findIndex(a => a.href === '/car_number'), 1);
      return { items: [...guestMenuData] };
    } else {
      return { items: [...data] };
    }
  }, [t, menuData]);

  useEffect(() => {
    setUserType(TokenService.getUserType());  
    setMenus(translateMenus());
  }, [translateMenus]);


  const loginGuest = useCallback(() => {
    const data = {
      access_token: 'guest',
      refresh_token: 'guest',
      user: {
        role: 'guest',
        user_id: 0,
        username: 'Guest'
      }
    };

    sessionStorage.setItem('isGuestLogout', 'false');
    TokenService.setUser(data);
    setIsAuthenticated(true);
  }, []);

  const accessGuest = useCallback(() => {
    if(location.pathname !== '/summary'){
      return;
    }
    loginGuest();
  }, [location, loginGuest]);

  useEffect(() => {
    if (!loadingGuest) {
      if (guestUser) {
        if (sessionStorage.getItem('isGuestLogout') && sessionStorage.getItem('isGuestLogout') === 'true') {
          setIsAuthenticated(false);
          TokenService.removeUser();
          return;
        }
        if (!localStorage.getItem('user_login_info')){
          accessGuest();
        } else {
          setIsAuthenticated(!(TokenService?.getUser()) ? false : true);
        }
      } else {
        setIsAuthenticated(!(TokenService?.getUser()) ? false : true);
      }
    }
  }, [loadingGuest, guestUser, accessGuest, userName]);

  const getConfigurationRoute = () => {
    return (
      configurationUrlList.map((url, index) => {
        return <Route path={'/configuration'+(index+1)} exact render={(props) => <Configuration {...props} iframe_src={url} />} key={index} />;
      })
    );
  };

  /*const getCarNumberRoute = () => {
    return (
      carNumberUrlList.map((url, index) => {
        return <Route path={'/car_number'+(index+1)} exact render={(props) => <Configuration {...props} iframe_src={url} />} key={index} />;
      })
    );
  };*/



  // --- for TopBar menu text display --- //
  const getTitleFromHref = useCallback((href: any, menuData: any) => {
    const formattedHref = href.startsWith('/') ? href : `/${href}`;
  
    for (const item of menuData) {
      if (item.href === formattedHref) {
        return item.title;
      }
  
      if (item.submenu) {
        for (const subitem of item.submenu) {
          if (subitem.href === formattedHref) {
            return `${item.title} ＞ ${subitem.title}`;
          }
        }
      }
    }
  
    return '';
  },[]);  

  const getCurrentPageAndSubmenuFromUrl = (pathname: string) => {
    const parts = pathname.split('/').filter(Boolean);
  
    const page = parts[0] || '';
    const submenu = parts[1] || '';  
  
    return { page, submenu };
  };

  useEffect(() => {
    const updateTitle = () => {
      const { page, submenu } = getCurrentPageAndSubmenuFromUrl(history.location.pathname);
      const href = submenu ? `${page}/${submenu}` : page;
      const title = getTitleFromHref(href, menuData);
      setTitle(title);
      localStorage.setItem('title', title);
    };
  
    // call updateTitle when page is loaded
    updateTitle();
  
    // hearing URL change
    const unlisten = history.listen(() => {
      updateTitle();
    });
  
    // clean
    return () => {
      unlisten();
    };
  }, [menuData, history, getTitleFromHref]);
  // --- for TopBar menu text display --- //




  const getApp = () => {
    return (
      <>
        <MainMenuContainer
          content={menus}
          logoMark={logoMarkSvg}
          logoText={LogoTextAppNameEn}
          keepOpenText={t('Keep Open')}
          autoHideText={t('Auto-Hide')}
          defaultMenuOpen={false}
          canAlwaysPin
        />
        <MainContainer>
          <StyledTopBar>
            <TopBar
              accountOptionText={t('Account Options')}
              loggedInUser={TokenService.getUser()}
              currentUserText={t('CURRENT USER')}
              hasLogout={false}
              logoutText={t('Logout')}
              userDrawerBespoke={<PasswordModal />}
              userDrawerFooter={{'icon': 'Information', 'title': '-'}}
              copySuccessMessage={t('Copied!')}
            />
            <TitleTopBar>{locationName} ＞ {title}</TitleTopBar>
          </StyledTopBar>
          <Role />

          {userType &&
            getConfigurationRoute()}
            
          <SecureRoute exact path={['/', '/login']}>
            <Redirect to='/summary' />
          </SecureRoute>
          <SecureRoute path='/summary' exact component={Summary} />
          <SecureRoute path='/location' exact component={Location} />
        </MainContainer>
      </>
    );
  };

  const getLoginRoute = () => {
    return (
      <>
        <Route render={() => <Login {...{guestUser, loginGuest}} />} />
      </>
    );
  };

  return (
    <Layout>
      {(!loadingGuest && !loadingMenus) ?
        !isAuthenticated ? getLoginRoute() : getApp()
        :
        <SpinnerBox>
          <Spinner size='large' styling='primary' />
          <Label htmlFor='loader' labelText={t('Loading') + '...'} />
        </SpinnerBox>}
    </Layout>
  );
};

export default App;
