import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Line, LineChart, ReferenceLine } from 'recharts';
import { Icon } from 'scorer-ui-kit';
import { LineUIOptions } from 'scorer-ui-kit/dist/LineUI';
import styled from 'styled-components';


const Container = styled.div`
  border-radius: 5px;
  display: grid;
  height: 220px;
  width: 180px;
  position: relative;
  object-fit: cover;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  @media (max-width:1438px) and (min-width:1280px) {
    width:48.7%;
  }
  background-color: white;
`;

const ImageBox = styled.div<{ color: string }>`
  width: 100%;
  height: 130px;
  position: relative;
  border-radius: 5px;
  background: ${props => props.color};
  margin: 0;
  padding: 0;
  @media only screen and (min-width: 1439px) {
    width: 100%;
  }
  > div {
    height: inherit;
    > img {
      height: inherit;
      border-radius: 5px;
    }
  }
`;

const LabelText = styled.p`
  font-size: 14px;
  max-width: 180px;
  overflow: hidden;
`;

const AboveContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StatusTextContainer = styled.span<{ color: string }>`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  color: ${(props) => props.color};
  font-weight: bold;
  text-align: center;
  transform: translateY(-50%);
  font-size: 50px;
  opacity: 0.4;
`;

interface ICard {
  options?: LineUIOptions;
  data: any;
}


const CardZone: FC<ICard> = ({ data }) => {

  const { t } = useTranslation(['CommonDict']);

  return (
    <Container>
      <AboveContainer>
        <LabelText>{t(data?.name)}</LabelText>
      </AboveContainer>

      <ImageBox color={(data?.count >= data?.max_slot ? '#ef7878' : data?.count < data?.max_slot ? '#87d58f' : '#96acbc')}>
        <LineChart width={150} height={100} data={data.graph_data}>
          <Line type='linear' dataKey='count' stroke='yellow' strokeWidth={2} dot={false} />
          <ReferenceLine y={data.max_slot} stroke='grey' strokeDasharray='10 10' />
        </LineChart>
        {data?.count >= data?.max_slot && (
          <StatusTextContainer color='#963d3d'>満</StatusTextContainer>
        )}
        {data?.count < data?.max_slot && (
          <StatusTextContainer color='#41b14d'>空</StatusTextContainer>
        )}
      </ImageBox>

      <AboveContainer>
        <LabelText title=''>空き数: {data?.count >= data?.max_slot ? 0 : (data?.max_slot - data?.count)}台</LabelText>
      </AboveContainer>
    </Container>
  );
};

export default CardZone;