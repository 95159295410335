import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'scorer-ui-kit';
import { LineUIOptions } from 'scorer-ui-kit/dist/LineUI';
import styled from 'styled-components';
import { Line, LineChart, ReferenceLine } from 'recharts';


const Container = styled.div`
  border-radius: 5px;
  display: grid;
  height: 220px;
  width: 180px;
  position: relative;
  object-fit: cover;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  @media (max-width:1438px) and (min-width:1280px) {
    width:48.7%;
  }
  background-color: white;
`;

const ImageBox = styled.div<{ color: string }>`
  width: 100%;
  height: 130px;
  position: relative;
  border-radius: 5px;
  background: ${props => props.color};
  margin: 0;
  padding: 0;
  @media only screen and (min-width: 1439px) {
    width: 100%;
  }
  > div {
    height: inherit;
    > img {
      height: inherit;
      border-radius: 5px;
    }
  }
`;

const LabelText = styled.p`
  font-size: 14px;
  max-width: 180px;
  overflow: hidden;
`;

const AboveContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StatusTextContainer = styled.span<{ color: string }>`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  color: ${(props) => props.color};
  font-weight: bold;
  text-align: center;
  transform: translateY(-50%);
  font-size: 50px;
  opacity: 0.4;
`;

interface ICard {
  options?: LineUIOptions;
  data: any;
}


const CardCount: FC<ICard> = ({ data }) => {

  const { t } = useTranslation(['CommonDict']);
  const congestionStatus = Math.round((data.last_count/data.total_max_slots) * 100);

  return (
    <Container>
      <AboveContainer>
        <LabelText>{t(data?.name)}</LabelText>
      </AboveContainer>

      <ImageBox color={(
        congestionStatus < data.congestion_threshold1 ? '#87d58f'
          : data.congestion_threshold1 < congestionStatus < data.congestion_threshold2 ? '#f0d935'
            : '#96acbc')}
      >
        <LineChart width={150} height={100} data={data.graph_data}>
          <Line type='linear' dataKey='count' stroke='yellow' strokeWidth={2} dot={false} />
          <ReferenceLine y={data.congestion_threshold1} stroke='grey' strokeDasharray='10 10' />
        </LineChart>
        {congestionStatus < data.congestion_threshold1 ? 
          <StatusTextContainer color='#41b14d'>空</StatusTextContainer> :
          data.congestion_threshold1 < congestionStatus < data.congestion_threshold2 ? 
            <StatusTextContainer color='#825f01'>混</StatusTextContainer> :
            <StatusTextContainer color='#963d3d'>満</StatusTextContainer>}
      </ImageBox>

      <AboveContainer>
        <LabelText title=''>現在駐車数: {data.last_count}台</LabelText>
      </AboveContainer>
    </Container>
  );
};

export default CardCount;