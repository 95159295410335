import React from 'react';
import IframeComponent from 'components/Iframe';

function Configuration({iframe_src}: {iframe_src: string }){
  return (
    <div>
      <IframeComponent
        src={iframe_src}
        width='100%'
        height='2000px'
      />
    </div>
  );
}

export default Configuration;
