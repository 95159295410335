import React, { useRef, useEffect } from 'react';

interface IframeProps {
  src: string;
  width: string;
  height: string;
  onLoad?: () => void;
}

const IframeComponent: React.FC<IframeProps> = ({ src, width, height, onLoad }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);


  useEffect(() => {
    const iframe = iframeRef.current;
    const onLoadHandler = () => {
      if (onLoad) {
        onLoad();
      }
    };

    if (iframe) {
      iframe.addEventListener('load', onLoadHandler);
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener('load', onLoadHandler);
      }
    };
  }, [onLoad]);


  return (
    <iframe
      title={'iframe'}
      ref={iframeRef}
      src={src}
      width={width}
      height={height}
      style={{ border: 'none' }} 
      scrolling={'no'}
    />
  );
};

export default IframeComponent;
