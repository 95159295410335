import moment from 'moment';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'scorer-ui-kit';
import { LineUIOptions } from 'scorer-ui-kit/dist/LineUI';
import styled from 'styled-components';
import { Line, LineChart, ReferenceLine } from 'recharts';


const Container = styled.div`
  border-radius: 5px;
  display: grid;
  height: 190px;
  width: 180px;
  position: relative;
  object-fit: cover;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  @media (max-width:1438px) and (min-width:1280px) {
    width:48.7%;
  }
  background-color: white;
`;

const ImageBox = styled.div<{ color: string }>`
  width: 100%;
  height: 130px;
  position: relative;
  border-radius: 5px;
  background: ${props => props.color};
  margin: 0;
  padding: 0;
  @media only screen and (min-width: 1439px) {
    width: 100%;
  }
  > div {
    height: inherit;
    > img {
      height: inherit;
      border-radius: 5px;
    }
  }
`;

const LabelText = styled.p`
  font-size: 14px;
  max-width: 180px;
  overflow: hidden;
`;

const AboveContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const StatusTextContainer = styled.span<{ color: string }>`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  color: ${(props) => props.color};
  font-weight: bold;
  text-align: center;
  transform: translateY(-50%);
  font-size: 50px;
  opacity: 0.4;
`;

const StatusTextContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTextContent = styled.span`
  font-size: 22px;
`;


interface ICard {
  options?: LineUIOptions;
  data: any;
}


const CardSlot: FC<ICard> = ({ data }) => {

  const { t } = useTranslation(['CommonDict']);

  return (
    <Container>
      <AboveContainer>
        <LabelText>{t(data?.name)}</LabelText>
      </AboveContainer>

      <ImageBox color={data.count === 0 ? '#87d58f' : data.count === 1 ? '#ef7878' : '#ddd'}>
        <LineChart width={150} height={100} data={data.graph_data}>
          <Line type='linear' dataKey='count' stroke='yellow' strokeWidth={2} dot={false} />
          {/*<ReferenceLine y={1} stroke='grey' strokeDasharray='10 10' />*/}
        </LineChart>
        {data.count === 0 && (
          <StatusTextContainer color='#41b14d'>
            <StatusTextContent>
              <span>空</span>
              {/*<StyledTextContent>{moment(data.last_update).minute()}分</StyledTextContent>*/}
            </StatusTextContent>
          </StatusTextContainer>
        )}
        {data.count === 1 && (
          <StatusTextContainer color='#963d3d'>
            <StatusTextContent>
              <span>満</span>
              {/*<StyledTextContent>{moment(data.last_update).minute()}分</StyledTextContent>*/}
            </StatusTextContent>
          </StatusTextContainer>
        )}
      </ImageBox>
    </Container>
  );
};

export default CardSlot;